<template>
  <div class="container">
    <el-tabs value="0" @tab-click="tabClick">
      <el-tab-pane label="未入住" name="0"></el-tab-pane>
      <el-tab-pane label="已入住" name="1"></el-tab-pane>
      <el-tab-pane label="退房" name="2"></el-tab-pane>
      <el-tab-pane label="已取消" name="3"></el-tab-pane>
    </el-tabs>

    <div class="handle-box" style="float: left;">
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
      <el-button
        v-loading="exportLoading"
        type="primary"
        icon="el-icon-upload2"
        @click="exportToExcel"
      >
        导出
      </el-button>
      <el-button type="danger" icon="el-icon-delete" @click="delAll">
        批量删除
      </el-button>
    </div>
    <div style="float: right;">
      <el-form
        ref="queryForm"
        :model="query"
        :inline="true"
        label-width="120px"
      >
        <el-form-item prop="depId">
          <el-cascader
            v-model="query.depIds"
            :options="departmentList"
            clearable
            :show-all-levels="false"
            :props="{
              value: 'id',
              label: 'name',
              children: 'children',
              multiple: true,
              emitPath: false
            }"
            placeholder="请选择所属部门"
          ></el-cascader>
        </el-form-item>
        <el-form-item prop="name">
          <el-input
            v-model="query.name"
            placeholder="请输入预约人姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="numbers">
          <el-input
            v-model="query.numbers"
            placeholder="请输入申请人联系方式、身份证号码、警号"
            style="width: 300px"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="audit">
          <el-select
            v-model="query.audit"
            placeholder="请选择审批状态"
            clearable
          >
            <el-option label="审批中" value="0"></el-option>
            <el-option label="通过" value="1"></el-option>
            <el-option label="不通过" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">
            搜索
          </el-button>
          <el-button icon="el-icon-reset" @click="resetForm('queryForm')">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="clear: both;"></div>

    <!--数据表格-->
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="正在加载 ..."
      border
      fit
      highlight-current-row
    >
      <el-table-column
        label="申请人姓名"
        prop="name"
        fixed="left"
        width="100"
      />
      <el-table-column label="联系方式" prop="telephone" width="150" />
      <el-table-column label="身份证号码" prop="idCard" width="150" />
      <el-table-column label="警号" prop="policeCard" width="150" />
      <el-table-column label="单位名称" prop="department" width="150" />
      <el-table-column
        label="申请日期"
        prop="applyTime"
        format="yyyy-MM-dd"
        width="150"
      />
      <el-table-column label="来访人数" prop="nums" />
      <el-table-column label="申请事由" prop="reason" />
      <el-table-column label="房间类型" prop="roomType">
        <!-- <template slot-scope="scope">
					<el-tag v-if="scope.row.roomType===1">标间</el-tag>
					<el-tag v-if="scope.row.roomType===2">套房</el-tag>
				</template> -->
      </el-table-column>
      <el-table-column label="住宿类型" prop="sleepType">
        <!-- <template slot-scope="scope">
					<el-tag v-if="scope.row.sleepType===1">差旅</el-tag>
					<el-tag v-if="scope.row.sleepType===2">午休</el-tag>
				</template> -->
      </el-table-column>
      <el-table-column label="入住时间" prop="beginTime" width="120" />
      <el-table-column label="离店时间" prop="endTime" width="120" />
      <el-table-column label="审批状态" prop="audit" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.audit === 0">审批中</el-tag>
          <el-tag v-if="scope.row.audit === 1" type="success">通过</el-tag>
          <el-tag v-if="scope.row.audit === 2" type="danger">不通过</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column label="签到状态" prop="signin" align="center">
				<template slot-scope="scope">
					<el-tag type="danger" v-if="scope.row.signin === 0">未签到</el-tag>
					<el-tag type="success" v-if="scope.row.signin === 1">已签到</el-tag>
				</template>
			</el-table-column> -->
      <el-table-column label="操作" width="350" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button
            v-if="roleCode === 'admin'"
            type="success"
            size="small"
            @click="onArchives(scope.row)"
          >
            查看流程
          </el-button>
          <el-button
            v-else
            type="success"
            size="small"
            @click="onAuditShow(scope.row)"
          >
            审批流程
          </el-button>
          <el-dropdown>
            <el-button type="primary">
              操作
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="onStatus(scope.row, 0)">
                未入住
              </el-dropdown-item>
              <el-dropdown-item @click.native="onStatus(scope.row, 1)">
                已入住
              </el-dropdown-item>
              <el-dropdown-item @click.native="onStatus(scope.row, 2)">
                退房
              </el-dropdown-item>
              <el-dropdown-item @click.native="onStatus(scope.row, 3)">
                取消预约
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            v-if="scope.row.signin === 1"
            size="small"
            @click="sendPassword(scope.row)"
          >
            开锁密码
          </el-button>
          <el-button type="danger" size="small" @click="onDel(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>

    <!-- 审批流程对话框 -->
    <el-dialog title="审批流程" width="50%" :visible.sync="selectDlgShow">
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="ruleForm" :model="form" label-width="180px">
          <el-form-item label="状态" prop="status" required>
            <el-radio-group
              v-model="form.status"
              size="small"
              @change="changeStatus"
            >
              <el-radio border label="1">通过</el-radio>
              <el-radio border label="2">不通过</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-show="isNextShow"
            label="是否进行下一步审批"
            prop="isNext"
            required
          >
            <el-radio-group v-model="form.isNext" size="small">
              <el-radio border label="1">是</el-radio>
              <el-radio border label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="isReasonShow" label="拒绝原因" prop="reason">
            <el-input
              v-model="form.reason"
              type="textarea"
              placeholder="请输入拒绝的原因"
              maxlength="30"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="selectDlgShow = false">关 闭</el-button>
        <el-button type="primary" @click="doSubmit()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="审批流程" width="50%" :visible.sync="processDialog">
      <el-steps :active="active" finish-status="success">
        <el-step
          title="基层领导审批"
          :status="steps.step1"
          :description="steps.descrip1"
        ></el-step>
        <el-step
          title="市局科室领导审批"
          :status="steps.step2"
          :description="steps.descrip2"
        ></el-step>
        <el-step
          title="市局领导审批"
          :status="steps.step3"
          :description="steps.descrip3"
        ></el-step>
      </el-steps>
      <div slot="footer" class="dialog-footer">
        <el-button @click="processDialog = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getAdminList,
  updateProcess,
  getAppointmentProcess,
  updateStatus,
  sendOpenKey,
  delAppointment
} from '@/api/sleep/list';
import { export_json_to_excel } from '@/assets/js/Export2Excel';
import { getDepartmentList } from '@/api/admin/department';
import Dict from '@/components/common/dict';

export default {
  name: 'SleepList',
  data() {
    return {
      query: {
        depId: '',
        depIds: [],
        numbers: '',
        name: '',
        status: 0,
        audit: ''
      },
      pageInfo: {
        startPage: 1,
        pageSize: 50,
        total: 0,
        pcode: 0,
        depId: '',
        depIds: [],
        numbers: '',
        name: '',
        status: 0,
        audit: -1
      },
      roleCode: '',
      userid: '',
      form: {
        id: '',
        status: '1',
        isNext: '1',
        reason: '',
        code: '',
        userid: ''
      },
      steps: {
        step1: 'wait',
        step2: 'wait',
        step3: 'wait',
        descrip1: '',
        descrip2: '',
        descrip3: ''
      },
      active: 0,
      departmentList: [],
      list: [],
      isNextShow: true,
      isReasonShow: false,
      listLoading: true,
      dlgLoading: false,
      processDialog: false,
      selectDlgShow: false,
      exportLoading: false,
      multipleSelection: []
    };
  },
  created() {
    this.roleCode = sessionStorage.getItem('roleCode');
    this.userid = sessionStorage.getItem('userId');
    this.fetchData();
    this.loadDepartmentList();
  },
  methods: {
    tabClick(tab) {
      let status = tab.name;
      this.pageInfo.status = status;
      this.query.status = status;
      this.onRefresh();
    },
    //加载部门列表
    loadDepartmentList() {
      this.departmentList = [];
      getDepartmentList().then(response => {
        this.departmentList = response.data;
      });
    },
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    search() {
      this.pageInfo.name = this.query.name;
      this.pageInfo.depId = this.query.depId;
      this.pageInfo.depIds = this.query.depIds;
      this.pageInfo.numbers = this.query.numbers;
      this.pageInfo.status = this.query.status;
      this.pageInfo.audit = this.query.audit;
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
    },
    //查询列表
    fetchData() {
      this.listLoading = true;
      this.pageInfo.pcode = this.roleCode;
      getAdminList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
        this.formatterData(this.list);
      });
    },
    //格式化数据
    formatterData(datalist) {
      for (var i = 0; i < datalist.length; i++) {
        datalist[i].status = Dict.getText(
          datalist[i].status,
          Dict.house_status
        );
        datalist[i].sleepType = Dict.getText(
          datalist[i].sleepType,
          Dict.sleep_type
        );
        datalist[i].roomType = Dict.getText(
          datalist[i].roomType,
          Dict.room_type
        );
      }
    },
    //流程审批
    onAuditShow(row) {
      this.selectDlgShow = true;
      this.isReasonShow = false;
      if (this.roleCode === '10003') {
        this.isNextShow = false;
        this.form.isNext = '0';
      } else {
        this.isNextShow = true;
        this.form.isNext = '1';
      }

      this.form.id = row.id;
      this.form.status = '1';
      this.form.reason = '';
      this.form.code = this.roleCode;
      this.form.userid = this.userid;
    },
    doSubmit() {
      updateProcess(this.form)
        .then(resp => {
          if (resp.code == 200) {
            this.$message({
              message: '操作成功',
              type: 'success'
            });
            this.onRefresh();
          } else {
            this.$message.error('操作失败');
          }
          this.selectDlgShow = false;
        })
        .catch(() => {
          console.log('服务器响应失败');
          this.selectDlgShow = false;
        });
    },
    //操作
    onStatus(row, status) {
      if (row.audit !== 1 && (status === 1 || status === 2)) {
        this.$message({
          message: '该用户审批没有通过，不能进行此操作',
          type: 'warning'
        });
      } else {
        updateStatus({ id: row.id, status: status }).then(resp => {
          if (resp.code == 200) {
            this.$message({
              message: '操作成功',
              type: 'success'
            });
            this.onRefresh();
          } else {
            this.$message.error('操作失败');
          }
        });
      }
    },
    //状态改变
    changeStatus(val) {
      if (val === '1') {
        this.isReasonShow = false;
      } else {
        this.isReasonShow = true;
      }
    },
    //查看审批流程
    getProcess(pid) {
      this.steps = {
        step1: 'wait',
        step2: 'wait',
        step3: 'wait'
      };
      this.active = 0;
      getAppointmentProcess({ id: pid }).then(resp => {
        if (resp.code == 200) {
          for (var i = 0; i < resp.data.length; i++) {
            let res = resp.data[i];
            let code = res.code;
            let status = res.status;
            let updateTime = res.updateTime;
            switch (code) {
              case 10001:
                this.active = 1;
                this.steps.descrip1 = updateTime;
                if (status == 1) {
                  this.steps.step1 = 'success';
                } else {
                  this.steps.step1 = 'error';
                }
                break;
              case 10002:
                this.active = 2;
                this.steps.descrip2 = updateTime;
                if (status == 1) {
                  this.steps.step2 = 'success';
                } else {
                  this.steps.step2 = 'error';
                }
                break;
              case 10003:
                this.active = 3;
                this.steps.descrip3 = updateTime;
                if (status == 1) {
                  this.steps.step3 = 'success';
                } else {
                  this.steps.step3 = 'error';
                }
                break;
            }
          }
        }
      });
    },
    onArchives(row) {
      this.processDialog = true;
      this.getProcess(row.id);
    },
    //发送开锁密码
    sendPassword(row) {
      sendOpenKey({ id: row.id }).then(resp => {
        if (resp.code == 200) {
          this.$alert(resp.msg, '成功', {
            confirmButtonText: '确定'
          });
        } else {
          this.$message.error('失败');
        }
      });
    },
    //批量删除
    delAll() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请至少选择一条数据');
        return;
      }
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        delAppointment({ ids: ids })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //删除
    onDel(data) {
      this.$confirm('您确定要删除此条记录吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delAppointment({ id: data.id })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //excel数据导出
    exportToExcel() {
      this.exportLoading = true;
      require.ensure([], () => {
        const tHeader = [
          '申请人姓名',
          '联系方式',
          '身份证号码',
          '警号',
          '单位名称',
          '申请日期',
          '来访人数',
          '申请事由',
          '房间类型',
          '住宿类型',
          '入住时间',
          '离店时间',
          '入住状态'
        ];
        const filterVal = [
          'name',
          'telephone',
          'idCard',
          'policeCard',
          'department',
          'applyTime',
          'nums',
          'reason',
          'roomType',
          'sleepType',
          'beginTime',
          'endTime',
          'status'
        ];
        const list = this.list;
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, '住宿预约');
        this.exportLoading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.el-divider--horizontal {
  margin: 50px 0px;
}
.el-dropdown {
  margin-left: 10px;
  margin-right: 10px;
}
</style>
