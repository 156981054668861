const repair_type = [
  { code: 1, text: '设备报修' },
  { code: 2, text: '其他报修' }
];

const repair_status = [
  { code: 1, text: '已下单' },
  { code: 2, text: '已派单' },
  { code: 3, text: '已完结' }
];

const appointment_status = [
  { code: 1, text: '已预约' },
  { code: 2, text: '已通知' },
  { code: 3, text: '已核销' },
  { code: 4, text: '取消预约' }
];

const movie_status = [
  { code: 1, text: '已预订' },
  { code: 2, text: '已使用' },
  { code: 3, text: '已取消' }
];

const house_status = [
  { code: 0, text: '未入住' },
  { code: 1, text: '已入住' },
  { code: 2, text: '退房' },
  { code: 3, text: '已取消' }
];

const room_type = [
  { code: 1, text: '标间' },
  { code: 2, text: '套房' }
];

const sleep_type = [
  { code: 1, text: '差旅' },
  { code: 2, text: '午休' }
];

const canteen_type = [
  { code: 1, text: '早餐' },
  { code: 2, text: '中餐' },
  { code: 3, text: '晚餐' }
];

const canteen_status = [
  { code: 1, text: '已预约' },
  { code: 2, text: '已消费' },
  { code: 3, text: '已取消' }
];

/**获取显示文本
 * */
function getText(code, arr) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].code === code) {
      return arr[i].text;
    }
  }
}

export default {
  repair_type,
  repair_status,
  appointment_status,
  movie_status,
  house_status,
  room_type,
  sleep_type,
  canteen_type,
  canteen_status,
  getText
};
