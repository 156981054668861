import request from '@/utils/request';

/**
 * 所有用户预约列表
 * @param {Object} data
 */
export function getAdminList(data) {
  return request({
    url: '/sleep/appointment/getAdminList',
    method: 'post',
    data
  });
}

/**
 * 流程审批
 * @param {Object} data
 */
export function updateProcess(data) {
  return request({
    url: '/sleep/appointment/updateProcess',
    method: 'post',
    data
  });
}

/**
 * 查看审批流程
 * @param {Object} data
 */
export function getAppointmentProcess(data) {
  return request({
    url: '/sleep/appointment/getAppointmentProcess',
    method: 'post',
    data
  });
}

/**
 * 修改入住状态（ 0-未入住 1-已入住 2-退房 3-取消预约）
 * @param {Object} data
 */
export function updateStatus(data) {
  return request({
    url: '/sleep/appointment/updateStatus',
    method: 'post',
    data
  });
}

/**
 * 发送开锁密码
 * @param {Object} data
 */
export function sendOpenKey(data) {
  return request({
    url: '/sleep/appointment/sendOpenKey',
    method: 'post',
    data
  });
}

/**
 * 删除操作
 */
export function delAppointment(data) {
  return request({
    url: '/sleep/appointment/del',
    method: 'post',
    data
  });
}
